







































































































































































































































.procedure-users-tree{

  .el-tree-node{
    padding:16px 0;
  }

  &-node{
    display: flex;
    justify-content: space-between;
    gap: 40px;

    &-name{
      margin-left: 10px;

      &-position{
        font-size: .9rem;
        color: gray;
      }
      &-full-name{
        font-size: 1.3rem;
        font-weight: bold;
      }
    }

    &-status{
      margin-top: 6px;
    }
  }

  & .el-tree-node__content{
    height: 50px !important;

    & .el-tree-node__expand-icon{
      font-size: 25px;
    }
  }

  //&-node{
  //  height:100px;
  //}
}
